import React, { useEffect } from "react";
import EntityReportBlock from "./entityBlock";
import { ReportingPeriodSelector } from "AppSrc/company/report/reportingPeriodSelector";
import { connect } from "react-redux";
import Im from "traec/immutable";
import { BSBtn } from "traec-react/utils/bootstrap";
import Traec from "traec";
import Swal from "sweetalert2";
import { alertSuccess } from "traec-react/utils/sweetalert";

const CompanyReportTitle = props => {
  if (!props.company) {
    return null;
  }
  let name = props.company.get("name");
  return <h3>Generate Indicator Report for {name}</h3>;
};

export const CompanyReport = props => {
  let { configDict, companyId, company } = props;

  const [config, setConfig] = React.useState(configDict);

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("company", "read"), new Traec.Fetch("company_indicator", "list")]
    });
    setConfig(configDict);
  }, [props, configDict]);

  const handleDateChange = (value, name) => {
    let dates = config.get("dates") || Traec.Im.Map();
    dates = dates.set(name, value);
    setConfig(config.set("dates", dates));
  };

  const setConfigInPath = (path, value) => {
    value = value.config || value.configDict || value;
    let subConfigDict = config.getInPath(path) || Traec.Im.Map();
    subConfigDict = subConfigDict.merge(value);
    let configDict = config.setInPath(path, subConfigDict);
    //console.log("Setting config in path", path, value)
    setConfig(configDict);
  };

  return (
    <div className="container-fluid">
      <CompanyReportTitle company={company} />

      <ReportingPeriodSelector
        fromDate={configDict.getInPath("dates.fromDate")}
        toDate={configDict.getInPath("dates.toDate")}
        handleDateChange={handleDateChange}
      />

      <EntityReportBlock
        configPath="master"
        setConfig={setConfigInPath}
        configDict={config.get("master") || Traec.Im.Map()}
        indent={0}
        companyId={companyId}
        includeSubElements={true}
      />

      <hr />
      <SaveButton companyId={companyId} configDict={config} />
      <DownloadButton companyId={companyId} configDict={config} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let configDict = company?.getInPath(`meta_json.report_config`) || Traec.Im.Map();
  let projects = company?.get("projects") || Im.Map();
  let indicators = company ? state.getInPath(`entities.companyObjects.byId.${companyId}.indicators`) : Im.Map();
  return { companyId, configDict, company, projects, indicators };
};

export default connect(mapStateToProps)(CompanyReport);

const SaveButton = props => {
  const onClick = () => {
    let { companyId, configDict } = props;

    let data = configDict ? configDict.toJS() : {};
    console.log("Saving report config data", data);

    let fetch = new Traec.Fetch("company", "patch", { companyId });
    fetch.updateFetchParams({
      body: {
        meta_json: {
          report_config: configDict
        }
      },
      postSuccessHook: () =>
        alertSuccess({
          text: "Report configuration saved.",
          onConfirm: () => {
            location.reload();
          }
        })
    });
    fetch.dispatch();
  };

  return <BSBtn text={"Save Config"} onClick={e => onClick(e)} extra_className={"ml-1 mr-1 pl-1 pr-1"} />;
};

const DownloadButton = props => {
  const onClick = () => {
    let { companyId, configDict } = props;

    let data = configDict ? configDict.toJS() : {};

    let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
    fetch.updateFetchParams({
      body: {
        type: "ASO_RSSB_PDF",
        payload: data
      }
    });
    Swal.queue([
      {
        title: "Compiling Report",
        confirmButtonText: "Download Report",
        html: `<p>This may take several minutes depending on the amount of indicators and metrics.</p><p>Please be patient.</p>`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch
            .rawFetch({ updateBody: true })
            .then(response => {
              if (response.status == 500) {
                return;
              }
              if (response) return response.blob();
            })
            .then(data => {
              let blobUrl = window.URL.createObjectURL(data);
              Swal.insertQueueStep({
                showConfirmButton: false,
                showCancelButton: false,
                title: "Download ready",
                html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="REPORT-${companyId.substring(
                  0,
                  8
                )}.pdf">Download</a>`
              });
            })
            .catch(err => {
              console.log(err);
              Swal.insertQueueStep({
                type: "error",
                title: "Error",
                text: "There was an error compiling your results.  Please contact support if the problem persists."
              });
            });
        }
      }
    ]);

    console.log("DATA", data);
  };

  return <BSBtn text={"Download Report"} extra_className={"ml-1 mr-1 pl-1 pr-1"} onClick={e => onClick(e)} />;
};
