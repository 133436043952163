import React from "react";
import { select } from "d3-selection";
import * as d3Geo from "d3-geo";
import * as d3Zoom from "d3-zoom";
import * as d3Scale from "d3-scale";
import * as topojson from "topojson-client";
import * as d3 from "d3";
//import * as geoData from "./uk-postcode-area";
//import * as geoData from "./test-data";

/*
https://github.com/roblascelles/uk-postcode-map/blob/master/step-4.html

Interactive zoomable
https://bl.ocks.org/mbostock/8fadc5ac9c2a9e7c5ba2
https://bl.ocks.org/mbostock/09dd5ad7d6bfd40187e0
https://bl.ocks.org/cmgiven/d39ec773c4f063a463137748097ff52f

Note the caveats on Northern Ireland
https://earthware.co.uk/blog/full-uk-postcodes-polygons-mostly-open-data/
*/

const MAPS = {
  eer: {
    asset: `/assets/data/uk_eer_simple.json`,
    objectKey: "uk_eer_geo",
    codeKey: "eer18cd",
    nameKey: "eer18nm"
  },
  county: {
    asset: `/assets/data/uk_county_simple.json`,
    objectKey: "uk_county_geo",
    codeKey: "ctyua17cd",
    nameKey: "ctyua17nm"
  },
  wpc: {
    asset: `/assets/data/uk_wpc_simple.json`,
    objectKey: "uk_wpc_geo",
    codeKey: "pcon17cd",
    nameKey: "pcon17nm"
  }
};

export const colorScale = d3Scale.scaleLinear().range(["rgb(198,219,239)", "rgb(8,48,107)"]);

function addCommas(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export class ColorScale extends React.Component {
  render() {
    let maxValue = this.props.maxValue || 1;
    let nCells = this.props.nCells || 64;

    let ints = [...Array(nCells).keys()];
    let floats = ints.map(i => maxValue * (i / (nCells - 1)));

    let colorCells = floats.map((f, i) => <td key={i} style={{ backgroundColor: f === 0 ? "#CCC" : colorScale(f) }} />);

    return (
      <React.Fragment>
        <span>0</span>
        <span className="float-right">{addCommas(maxValue.toFixed(2))}</span>
        <table height="30px" width="100%">
          <tbody>
            <tr>{colorCells}</tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export class GeoReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resolution: "eer",
      data_type: "supplier", // supplier | employee | training
      color_field: "num_suppliers",
      transform: null,
      charts: {},
      forceUpdate: false
    };

    this.createChart = this.createChart.bind(this);
    this.loadShapes = this.loadShapes.bind(this);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.loadShapes();
    this.createChart();
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { draggedSize, hasSideBar } = this.props;

    let maxColorValue = colorScale.domain()[1];
    if (maxColorValue != this.maxColorValue) {
      this.maxColorValue = maxColorValue;
      return true;
    }
    if (draggedSize != nextProps.draggedSize || hasSideBar != nextProps.hasSideBar) {
      console.log("RESIZING CANVAS DUE TO SIDEBAR RESIZE");
      return true;
    }
    if (this.parentEl.clientWidth != this.domEl.clientWidth) {
      console.log("RESIZING DUE TO ELEMENT SIZE MISMATCH");
      return true;
    }
    if (this.state.showColorScale != nextState.showColorScale) {
      return true;
    }
    if (nextState.forceUpdate) {
      return true;
    }
    if (this.state.resolution == nextState.resolution) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    //console.log("COMPONENT IS UPDATING ")
    this.createChart();
    this.setState({ forceUpdate: false });
  }

  loadShapes() {
    // Load the basic topojson data

    // Load the topojson files from https://www.opendoorlogistics.com/downloads/
    for (let resolution of ["eer", "county", "wpc"]) {
      d3.json(MAPS[resolution].asset).then(data => {
        this.setState({
          charts: { ...this.state.charts, [resolution]: data },
          forceUpdate: true
        });
      });
    }
  }

  createChart() {
    const svgEl = this.domEl;

    // Set the zoom characteristics
    let zoom = d3Zoom
      .zoom()
      .scaleExtent([1, 200])
      .on("zoom", zoomed.bind(this));

    const svg = select(svgEl);
    svg.selectAll("*").remove();

    // Append an inner element and zoom
    let g = svg.append("g");
    if (this.state.transform) {
      g.attr("transform", this.state.transform);
    }

    // Attach the zoom
    svg.call(zoom);

    const width = this.domEl ? this.domEl.clientWidth : svg.attr("width");
    const height = this.domEl ? this.domEl.clientHeight : svg.attr("height");

    let resolution = this.state.resolution;
    let data_type = this.state.data_type;

    let objectKey = MAPS[resolution].objectKey;

    // Get the data from state
    let uk = this.state.charts[resolution];
    if (!uk) {
      return null;
    }

    // Generate colors from first character of postcode
    let areas = uk.objects[objectKey].geometries;
    let areadata = {};
    let data = this.props.data || {};
    if (data) {
      data = data[resolution] || {};
    }
    let codeKey = MAPS[resolution].codeKey;
    let nameKey = MAPS[resolution].nameKey;

    for (let a of areas) {
      let code = a.properties[codeKey];
      let value = data[code] ? data[code][this.state.color_field] || 0 : 0;

      areadata[code] = value;
    }

    let projection = d3Geo
      .geoAlbers()
      .center([0, 55.4])
      .rotate([4.4, 0])
      .parallels([50, 60])
      .scale(height * 3.5)
      .translate([width / 2, height / 2.5]);

    let path = d3.geoPath().projection(projection);

    // Set the range of the colorScale
    let colorValues = Object.values(areadata);
    colorScale.domain(d3.extent(colorValues));

    function get_tooltip_text(code) {
      if (data_type == "supplier") {
        return (
          `Num suppliers: ${data[code] ? data[code].num_suppliers : 0}\n` +
          `Supplier spend: \u00A3${data[code] ? addCommas(data[code].supplier_spend.toFixed(2)) : 0}`
        );
      } else if (data_type == "employee") {
        return `Num employees: ${data[code] ? data[code].num_employees : 0}`;
      } else if (data_type == "training") {
        return (
          `Num trainings: ${data[code] ? data[code].num_trainings : 0}\n` +
          `Num accredited trainings: ${data[code] ? data[code].num_accredited_trainings : 0}\n` +
          `Num non-accredited trainings: ${data[code] ? data[code].num_nonaccredited_trainings : 0}\n`
        );
      }
      return "";
    }

    // Color the polygons
    g.selectAll(".postcode_area")
      .data(topojson.feature(uk, uk.objects[objectKey]).features)
      .enter()
      .append("path")
      .attr("class", "postcode_area")
      .attr("d", path)
      .style("fill", function(d) {
        var value = areadata[d.properties[codeKey]];
        if (value) {
          return colorScale(value);
        } else {
          return "#CCC";
        }
      })
      .attr("stroke", "black")
      .attr("stroke-width", 1 / (this.state.transform ? this.state.transform.k : 1))
      //.attr("vector-effect", "non-scaling-stroke")
      .append("svg:title")
      .attr("transform", function(d) {
        return "translate(" + path.centroid(d) + ")";
      })
      .attr("dy", ".35em")
      .text(function(d) {
        let code = d.properties[codeKey];
        return `${d.properties[nameKey]}\n` + `RegionID: ${code}\n` + get_tooltip_text(code);
      });

    function getLevel(k) {
      let newResolution = null;
      if (k > 11) {
        newResolution = "wpc";
      } else if (k > 4) {
        newResolution = "county";
      } else {
        newResolution = "eer";
      }
      return newResolution;
    }

    function zoomed() {
      // Here you can set some action based on the zoom level (such as fetching new data)
      let transform = d3.event.transform;
      let newState = { transform };
      //console.log("ZOOMED", transform, transform.k, this.state.transform ? this.state.transform.k : null);
      if (transform && this.state.transform && transform.k != this.state.transform.k) {
        // Work out what resolution to view
        let newResolution = getLevel(transform.k);
        if (newResolution != getLevel(this.state.transform.k)) {
          Object.assign(newState, { resolution: newResolution });
        }
      }
      this.setState(newState);
      g.attr("transform", transform);

      let strokeWidth = 1 / transform.k;
      g.selectAll(".postcode_area").attr("stroke-width", strokeWidth);
    }
  }

  getWidth() {
    let width = this.parentEl ? this.parentEl.clientWidth : 1400;
    //console.log("GOT WIDTH", width);
    return width;
  }

  getHeight() {
    let height = this.parentEl ? this.parentEl.clientHeight : 1200;
    //console.log("GOT HEIGHT", height);
    return height;
  }

  render_sub_options() {
    if (this.state.data_type == "supplier") {
      return (
        <React.Fragment>
          <option value="num_suppliers">Num. Suppliers</option>
          <option value="supplier_spend">Supplier Spend (GBP)</option>
        </React.Fragment>
      );
    } else if (this.state.data_type == "employee") {
      return (
        <React.Fragment>
          <option value="num_employees">Num. Employees</option>
        </React.Fragment>
      );
    } else if (this.state.data_type == "training") {
      return (
        <React.Fragment>
          <option value="num_trainings">Num. Trainings</option>
          <option value="num_accredited_trainings">Num. accredited Trainings</option>
          <option value="num_nonaccredited_trainings">Num. non-accredited Trainings</option>
        </React.Fragment>
      );
    }
  }

  render() {
    console.log("Component is rendering", this.state.showColorScale);
    return (
      <div className="container-fluid m-0 p-0" id="mapContainer" ref={node => (this.parentEl = node)}>
        <div className="form-group">
          {/* Set the resolution */}
          <select
            className="custom-select"
            style={{ width: "20%" }}
            value={this.state.resolution}
            onChange={e => this.setState({ resolution: e.target.value })}
          >
            <option value="eer">Electoral Regions</option>
            <option value="county">Counties</option>
            <option value="wpc">Constituencies</option>
          </select>

          {/* Set the data type */}
          <select
            className="custom-select"
            style={{ width: "20%" }}
            value={this.state.data_type}
            onChange={e =>
              this.setState({
                data_type: e.target.value,
                color_field: `num_${e.target.value}s`,
                forceUpdate: true
              })
            }
          >
            <option value="supplier">Suppliers</option>
            <option value="employee">Employees</option>
            <option value="training">Training</option>
          </select>

          {/* Set the color field */}
          <select
            className="custom-select"
            style={{ width: "20%" }}
            value={this.state.color_field}
            onChange={e => this.setState({ color_field: e.target.value, forceUpdate: true })}
          >
            {this.render_sub_options()}
          </select>

          <button
            className="btn btn-secondary float-right"
            onClick={e => {
              this.setState({ showColorScale: !this.state.showColorScale });
            }}
          >
            {this.state.showColorScale ? "Hide" : "Show"} color scale
          </button>
        </div>
        {this.state.showColorScale ? <ColorScale maxValue={colorScale.domain()[1]} /> : null}
        <svg ref={node => (this.domEl = node)} width={this.getWidth()} height={this.getHeight()} className={"vh-100"} />
      </div>
    );
  }
}

export default GeoReport;
