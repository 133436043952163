import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import CompanyHome from "./home";
import CompanyMembers from "traec-react/members/companyMembers";
import CompanyDetails from "./details";
import CompanyIndicators from "./indicators/";
import CompanyMap from "./map";

import CompanyHealthcheckPage from "storybook-dashboard/dashboard/health/page";
import CompanyExportPage from "storybook-dashboard/dashboard/export/company/page";

import RecipientEmailReport from "traec-react/emails/report/recipientEmailReport";
import CompanyReport from "AppSrc/company/report/index";
import CompanyIndicatorTarget from "AppSrc/company/indicators/indicatorTarget";
import EmailSettings from "traec-react/emails/settings/index";
import EmailReport from "traec-react/emails/report";
import { getCompanyPermissions } from "traec/utils/permissions/company";

import { setNavBar } from "./navbar";

class CompanyRouterBase extends React.Component {
  constructor(props) {
    super(props);

    this.requiredFetches = [new Traec.Fetch("company_permission", "list")];
  }

  componentDidMount() {
    //console.log("Company Router mounted", this.props)
    Traec.fetchRequiredFor(this);
    setNavBar(this.props);
  }

  componentDidUpdate() {
    //console.log("Company Router updated", this.props)
    Traec.fetchRequiredFor(this);
    setNavBar(this.props);
  }

  render() {
    let { userPermissions } = this.props
    return (
      <React.Fragment>
        <Switch>
          {/* there will only ever be one child in the Switch */}

          {/* Pages for Members, Invites and Auth */}
          <Route
            exact
            path={`/company/:_companyId/members`}
            render={props => (
              <CompanyMembers
                {...props}
                initFormFields={Traec.Im.fromJS({
                  meta_json__push_below: true,
                  meta_json__auto_accept_if_user_exists: true
                })}
              />
            )}
          />

          {/* Pages for Editing the Company Details */}
          <Route exact path={`/company/:_companyId/details`} component={CompanyDetails} />

          {/* Pages for Editing the Company Details */}
          <Route exact path={`/company/:_companyId/indicators`} component={CompanyIndicators} />
          <Route exact path={`/company/:_companyId/indicator/:indicatorId/target`} component={CompanyIndicatorTarget} />

          {/* Pages for Generating a pdf of indicators and metrics at a company level */}
          <Route path={`/company/:_companyId/report/`} component={CompanyReport} />

          {/* Pages for report status healthcheck */}
          <Route path={`/company/:_companyId/health/`} component={CompanyHealthcheckPage} />

          {/* Pages for report status healthcheck */}
          <Route path={`/company/:_companyId/export/`} render={(props) => (<CompanyExportPage {...props} isAdmin={userPermissions?.get("is_admin")} />)} />

          {/* Route for the Company Map */}
          <Route
            exact
            path={`/company/:_companyId/map`}
            render={props => <CompanyMap {...{ ...props, ...this.props }} />}
          />

          {/* Pages for email statistics */}
          <Route exact path={`/company/:_companyId/email/settings`} component={EmailSettings} />
          <Route exact path={`/company/:_companyId/email/report`} component={EmailReport} />
          <Route exact path={`/company/:_companyId/email/report/:recipientId`} component={RecipientEmailReport} />

          {/* Default render homepage if no path matched */}
          <Route component={CompanyHome} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);
  let userPermissions = getCompanyPermissions(state, companyId);
  let tenant_meta = state.getInPath(`entities.tenant.meta_json`) || Traec.Im.Map();
  return { companyId, userPermissions, tenant_meta };
};

const CompanyRouter = connect(mapStateToProps)(CompanyRouterBase);

export { CompanyRouter };
