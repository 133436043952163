import React, { useEffect } from "react";
import { ProjectList } from "./projectFields";
import { connect } from "react-redux";
import Im from "traec/immutable";
import { TextField } from "./textField";
import { IndicatorGroupList } from "./indicatorGroups";
import Traec from "traec";

/* This is a block that may be recusively called to generate
a reporting block of iputs with:
  * preamble
  * indicator groups (sustainability issues)
  * postamble
*/

export const EntityReportBlock = props => {
  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("company_indicator", "list"), new Traec.Fetch("project_indicator", "list")]
    });
  });

  const { configDict, configPath, setConfig, indent, includeSubElements, projects, indicators } = props;

  let marginText = indent ? `m-${indent * 3}` : null;

  let sub_elements = includeSubElements ? (
    <React.Fragment>
      <ProjectList
        all_projects={projects}
        projects={configDict.get("projects") || Im.List()}
        parent_groups={configDict.get("indicator_groups") || Im.List()}
        configPath={configPath}
        setConfig={setConfig}
      />
      <hr />
    </React.Fragment>
  ) : null;

  return (
    <div className={`container-fluid p-1 ${marginText}`}>
      <hr />
      <TextField type={"preamble"} configDict={configDict} setConfig={setConfig} configPath={configPath} />
      <hr />

      <IndicatorGroupList
        indicators={indicators}
        groups={configDict.get("indicator_groups") || Im.List()}
        configPath={configPath}
        setConfig={setConfig}
        configDict={configDict}
      />
      <hr />

      {sub_elements}

      <TextField type={"postamble"} configDict={configDict} setConfig={setConfig} configPath={configPath} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { companyId, projectId } = ownProps;
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let projects = company ? company.get("projects") : Im.Map();
  let indicators = state.getInPath(`entities.companyObjects.byId.${companyId}.indicators`) || Im.Map();
  // If a projectId is passed in then use the indicators for the project
  if (projectId) {
    indicators = state.getInPath(`entities.projectObjects.byId.${projectId}.indicators`) || Im.Map();
  }
  return { companyId, projectId, company, projects, indicators };
};

export default connect(mapStateToProps)(EntityReportBlock);
