import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { CompanyPermission } from "traec/utils/permissions/company";
import { Spinner } from "traec-react/utils/entities";
import TipsSpinner from "../../utils/spinners/tipsSpinner";

class CompanyAvailableIndicators extends React.Component {
  constructor(props) {
    super(props);

    let { companyId } = props;

    this.state = {
      showAddForm: false
    };

    // Data required from the API for this Component
    this.requiredFetches = [
      new Traec.Fetch("company_indicator", "list"),
      new Traec.Fetch(
        "company_indicator",
        "list",
        { companyId },
        {
          preUpdateHook: args => {
            return { ...args, allAvailable: true };
          }
        }
      )
    ];

    this.addExistingIndicator = this.addExistingIndicator.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  addExistingIndicator(e, indicator) {
    e.preventDefault();
    let { companyId } = this.props;
    let fetch = new Traec.Fetch("company_indicator", "post", { companyId, fromExistingId: indicator.get("uid") });
    fetch.dispatch();
  }

  render_indicator_list_headers() {
    return (
      <div className="row">
        <div className="col-sm-7">
          <b>{"Name"}</b>
        </div>
        <div className="col-sm-4">
          <b>{"Category"}</b>
        </div>
        <div className="col-sm-1" />
      </div>
    );
  }

  render_row(indicator, index) {
    console.log("Rendering indicator from project", index, indicator?.toJS());
    return (
      <div className="row m-0 p-0" key={index} style={{ backgroundColor: (index + 1) % 2 ? "#ddd" : "" }}>
        <div className="col-sm-7">{indicator.getInPath("resultBaseMetric.name") || indicator.get("name")}</div>
        <div className="col-sm-4">{indicator.getInPath("resultBaseMetric.category")}</div>
        <div className="col-sm-1">
          <button
            className="btn btn-sm btn-primary pt-0 pb-0 mt-1 mb-1"
            onClick={e => this.addExistingIndicator(e, indicator)}
          >
            Add
          </button>
        </div>
      </div>
    );
  }

  render() {
    let { companyId, company, companyIndicators, companyAvailableIndicators } = this.props;

    if (!company || !companyAvailableIndicators || !companyIndicators) {
      return <TipsSpinner message="Loading Indicator List. It might take a moment" />;
    }

    let unusedAvailableIndicators = companyAvailableIndicators
      .toList()
      .filter(i => companyIndicators.get(i.get("uid")) == null);

    if (!unusedAvailableIndicators.size) {
      return (
        <div>
          <p>There are no indicators from sub-projects that are not already in use.</p>
          <button className="btn btn-sm btn-secondart" onClick={this.props.toggleShowHandler}>
            Close
          </button>
        </div>
      );
    }

    let listComponents = unusedAvailableIndicators.map((indicator, i) => this.render_row(indicator, i));

    return (
      <CompanyPermission companyId={companyId} requiresAdmin={true}>
        {this.render_indicator_list_headers()}
        <hr />
        {listComponents}
        <hr />
        <button className="btn btn-sm btn-secondart" onClick={this.props.toggleShowHandler}>
          Close
        </button>
      </CompanyPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { companyId } = ownProps;
  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let companyIndicators = state.getInPath(`entities.companyObjects.byId.${companyId}.indicators`);
  let companyAvailableIndicators = state.getInPath(`entities.companyObjects.byId.${companyId}.availableIndicators`);
  return { company, companyIndicators, companyAvailableIndicators };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAvailableIndicators);
