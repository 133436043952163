import React from "react";
import Im from "traec/immutable";
import { BSBtn } from "traec-react/utils/bootstrap";
import { IndicatorGroup } from "./indicatorGroup";

export const IndicatorGroupList = props => {
  let { configPath, setConfig, groups, indicators, configDict } = props;

  const addGroup = () => {
    let new_groups = groups.push(Im.Map({ name: null }));
    setConfig(configPath, configDict.set("indicator_groups", new_groups));
  };

  const deleteGroup = index => {
    let new_groups = groups.splice(index, 1);
    setConfig(configPath, configDict.set("indicator_groups", new_groups));
  };

  const moveGroup = (index, move_dist) => {
    let new_index = index + move_dist;
    new_index = new_index > groups.size ? groups.size : new_index;
    new_index = new_index < 0 ? 0 : new_index;
    let item = groups.get(index);
    let new_groups = groups.splice(index, 1);
    new_groups = new_groups.splice(new_index, 0, item);
    setConfig(configPath, configDict.set("indicator_groups", new_groups));
  };

  const removeGroup = () => {
    let new_groups = groups.delete(groups.size - 1);
    setConfig(configPath, configDict.set("indicator_groups", new_groups));
  };

  let group_components = groups.map((group, index) => (
    <IndicatorGroup
      key={index}
      index={index}
      indicators={indicators}
      configDict={group}
      configPath={`${configPath}.indicator_groups.${index}`}
      setConfig={setConfig}
      deleteGroup={deleteGroup}
      moveGroup={moveGroup}
    />
  ));

  return (
    <div>
      <h3 className="mt-2 mb-2 pt-2 pb-2">Indicator Groups</h3>
      {group_components}
      <Buttons groups={groups} addGroup={addGroup} removeGroup={removeGroup} />
      <div style={{ clear: "both" }} />
    </div>
  );
};

const Buttons = props => {
  let { groups, addGroup, removeGroup } = props;
  if (!groups.size) {
    return (
      <div>
        <BSBtn text={"Add New Group"} onClick={e => addGroup(e)} />
      </div>
    );
  }
  return (
    <div>
      <BSBtn text={"Add New Group"} onClick={e => props.addGroup(e)} />
      <BSBtn extra_className="mr-1" text={"Remove last Group"} onClick={e => removeGroup(e)} />
    </div>
  );
};
